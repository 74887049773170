@font-face {
  font-family: "Horizon";
  src: url("Horizon-Bold.eot");
  src: url("Horizon-Bold.eot?#iefix") format("embedded-opentype"),
    url("Horizon-Bold.woff2") format("woff2"),
    url("Horizon-Bold.woff") format("woff"),
    url("Horizon-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
