@import "../assets/fonts/horizon/stylesheet.css";
@import "../assets/fonts/dm-sans/stylesheet.css";
@import "../assets/fonts/georgia/stylesheet.css";
@import "../assets/fonts/druk-wide-bold/stylesheet.css";

html {
  width: 100%;
  scroll-padding-top: 13vh !important;
  @apply overflow-x-hidden bg-dark;
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 7vh !important;
    margin-top: 0;
  }
}

/* @media (min-width: 1024px) {
  *,
  body,
  html {
    cursor: none;
  }

  button {
    cursor: none !important;
  }
} */
